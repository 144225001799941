.dialog__interface-video
  .dialog__interface-testVideo
    height: 100%
    img
      height: 100%



@media (max-width: 767px)
  .dialog__interface-video
    max-height: 280px
    .dialog__interface-testVideo
      height: 100%
      img
        height: 100%