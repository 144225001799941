// Тут общие стили для секции
.section-dialog
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  .row
    display: flex
    align-items: center
    justify-content: center
  &__row
    display: flex
    align-items: center
    justify-content: center
    padding: 0 30px
    height: 527px

@media (max-width: 1200px)
@media (max-width: 767px)
  .section-dialog
    &__row
      height: 550px

@media (max-height: 750px)
  .section-dialog
    &__row
      padding: 0 4vh
      height: 70.266vh

@media (max-width: 1200px)
  .section-dialog
    &__row
      padding: 0

@media (max-width: 767px) and (max-height: 750px)
  .section-dialog
    align-items: flex-start
    &__row
      height: 86.266vh


@import './dialog_interface/_interface_all'
@import './dialog_chat/_chat_all'

