.dialog__interface-indicator
  width: 100%
  height: 100%
  position: relative
  border-radius: $radius
  background-color: rgba($white_color, 0.8)
  backdrop-filter: blur(3px)
  &-icon
    position: relative
    border-radius: $radius
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    #textWaiting
      $waiting: waiting infinite
      position: absolute
      color: #fff
      font-size: 16px
      opacity: 0
      pointer-events: none
      top: 45%
      left: 41%
      &[data-visible='true']
        opacity: 1
        pointer-events: auto
        .dot
          &-1
            animation:1s $waiting
          &-2
            animation:1.5s $waiting
          &-3
            animation:2s $waiting
    svg
      &.cloud
        width: 71.428%
        $edit-size-large: edit-size-large infinite alternate
        $edit-size-small: edit-size-small infinite alternate
        $rotate-clouds: rotate-clouds infinite linear
        &.preanimate
          g
            animation: none
          .rect
            width: 0
            height: 0
            x: 40%
            y: 20%
            animation: none
            &-1
              width: 82.699%
              height: 93.485%
              x: 8.65%
              y: 3.2575%
        .big_clouds
          &-item
            transform-box: fill-box
            animation: 15s $rotate-clouds
            transform-origin: center
        .small_clouds
          &-item
            transform-box: fill-box
            animation: 20s $rotate-clouds
            transform-origin: center
        .rect
          fill: #6FC6E2
          transition: all 0.5s linear
          &-1
            x: 79.5px
            y: 33.5px
            width: 143px
            height:143px
          &-2
            x: 106.5px
            y: 13px
            width: 89px
            height:89px
            animation: 2s $edit-size-large
          &-3
            x: 156.5px
            y: 38.8px
            width: 89px
            height:89px
            animation: 1.5s $edit-size-large
          &-4
            x: 156.5px
            y: 82.1px
            width: 89px
            height:89px
            animation: 1s $edit-size-large
          &-5
            x: 106.5px
            y: 109px
            width: 89px
            height:89px
            animation: 2s $edit-size-large
          &-6
            x: 57.5px
            y: 82.1px
            width: 89px
            height:89px
            animation: 1.5s $edit-size-large
          &-7
            x: 57.5px
            y: 38.8px
            width: 89px
            height:89px
            animation: 1s $edit-size-large
          &-8
            x: 10px
            y: 190.25px
            width: 31px
            height:31px
            animation: 1.6s $edit-size-small
          &-9
            x: 10px
            y: 184.75px
            width: 31px
            height:31px
            animation: 1.4s $edit-size-small
          &-10
            x: 16px
            y: 182.5px
            width: 31px
            height:31px
            animation: 1.2s $edit-size-small
      &.circle
        $pulse-circle: pulse-circle infinite alternate
        width: 47.802%
        .rect
          fill: transparent
          width: 0
          height: 0
          transition: all 0.5s linear
          &-1,&-2,&-3,&-4
            fill: #FFC255
            width: 200px
            height: 200px
            animation: 1.5s $pulse-circle
            x: 0
            y: 0
          &-5,&-6,&-7,&-8,&-9,&-10
            x: 50%
            y: 50%
            fill: #FFC255
          &-8,&-9,&-10
            transition: all 1s ease

      &.equalizer
        $edit-height-equalizer: edit-height-equalizer infinite alternate
        $edit-y-equalizer: edit-y-equalizer infinite alternate
        width: 79.674%
        .rect
          fill: transparent
          width: 0
          height: 0
          transition: fill 0s linear, all 0.5s linear
          &-1
            fill: #FFC255
            width: 71px
            x: 0px
            y: 10.5px
            height: 110px
            animation: 0.8s $edit-height-equalizer, 0.8s $edit-y-equalizer
          &-2
            fill: #FF6C4C
            width: 71px
            x: 73px
            y: 0px
            height: 131px
            animation: 0.7s $edit-height-equalizer, 0.7s $edit-y-equalizer
          &-3
            fill: #00BA97
            width: 71px
            x: 146px
            y: 8.5px
            height: 114px
            animation: 0.6s $edit-height-equalizer, 0.6s $edit-y-equalizer
          &-4
            fill: #6FC6E2
            width: 71px
            x: 219px
            y: 15.5px
            height: 100px
            animation: 0.5s $edit-height-equalizer, 0.5s $edit-y-equalizer

@keyframes waiting
  0%
    opacity: 1
  50%
    opacity: 0
  100%
    opacity: 1

@keyframes rotate-clouds
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes edit-size-large
  0%
    width: 84px
    height: 84px
  50%
    width: 90px
    height: 90px
  100%
    width: 84px
    height: 84px

@keyframes edit-size-small
  0%
    width: 28px
    height: 28px
  50%
    width: 35px
    height: 35px
  100%
    width: 28px
    height: 28px

@keyframes pulse-circle
  0%
    width: 184px
    height: 184px
    x: 8px
    y: 8px
  25%
    width: 198px
    height: 198px
    x: 1px
    y: 1px
  50%
    width: 176px
    height: 176px
    x: 12px
    y: 12px
  75%
    width: 198px
    height: 198px
    x: 1px
    y: 1px
  100%
    width: 184px
    height: 184px
    x: 8px
    y: 8px

@keyframes edit-height-equalizer
  0%
    height: 100px
  50%
    height: 129px
  100%
    height: 100px

@keyframes edit-y-equalizer
  0%
    y: 15.5px
  50%
    y: 1px
  100%
    y: 15.5px



@media (max-width: 1200px)
  .dialog__interface-indicator
    &-icon
      #textWaiting
        left: 38%

@media (max-width: 767px)
  .dialog__interface-indicator
    max-height: 240px
    &-icon
      #textWaiting
        font-size: 15px
        top: 42.5%
        left: 42%
      svg
        &.cloud
          width: 60%

@media (max-width: 576px)
  .dialog__interface-indicator
    &-icon
      #textWaiting
        left: 15px
        top: 20px
        color: #000
        font-size: 18px
      svg
        &.cloud
          max-width: 250px
        &.circle
          max-width: 200px
        &.equalizer
          max-width: 268px

@media (max-height: 750px)
  .dialog__interface-indicator
    border-radius: 4vh
    &-icon
      border-radius: 4vh
      #textWaiting
        font-size: 2.133vh

@media (max-width: 1200px) and (max-height: 750px)
  .dialog__interface-indicator
    &-icon
      #textWaiting
        font-size: 2.666vh

@media (max-width: 767px) and (max-height: 750px)
  .dialog__interface-indicator
    margin: 0 1.348vh
    &-icon
      #textWaiting
        font-size: 2vh
      svg
        width: 89.333%

@media (max-width: 576px) and (max-height: 750px)
  .dialog__interface-indicator
    &-icon
      #textWaiting
        left: 2vh
        top: 2.666vh
        font-size: 2.266vh