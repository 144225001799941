.dialog__chat-page
  display: flex
  flex-direction: column
  height: 100%
  position: relative
  z-index: 1
  .backdrop
    border-radius: $radius
    position: absolute
    width: 100%
    height: 100%
    left: 0
    right: 0
    background-color: rgba($white_color, 0.8) // 0.8
    backdrop-filter: blur(3px)
    z-index: -1
  &-header
    height: 50px
    padding: 6px 10px
    background-color: $white_color
  &-close
    display: flex
    align-items: center
    justify-content: center
    width: 39px
    height: 39px
    border-radius: 100%
    background-color: rgba($ui_color, 0.1)
    svg
      width: 12px
      height: 12px
      path
        fill: $ui_color
    &:active
      background-color: rgba($ui_color, 0)
      transform: translateX(-10%)
      svg
        transform: scale(1.3)
    @media (hover: hover)
      &:hover
        background-color: rgba($ui_color, 0)
        transform: translateX(-10%)
        svg
          transform: scale(1.3)
  &-contents-wrapper:not([data-id="#chat"])
    background-color: rgba(255, 255, 255, 0.75)
    height: 100%
    padding-bottom: 20px
    padding-right: 8px
    border-radius: 30px
    overflow: hidden
  &-widget
    height: 100%
    .dialog__chat-page-body
      padding: 0 12px 0 20px
      overflow-y: auto
      height: calc(100% - 50px)
      &::-webkit-scrollbar
        width: 2px
        border-radius: 20px
        background-color: rgba($ui_color, 0.1)
      &::-webkit-scrollbar-thumb
        border-radius: 20px
        background-color: rgba($ui_color, 0.3)
    &-image
      max-width: 190px
      float: left
      padding-right: 10px
    &-title
      font-weight: 700
      font-size: 20px
      margin-bottom: 20px
    &-descr
      font-size: 16px
      h4
        font-weight: 700
        text-align: center
      p
        margin: 0
        &:not(:last-child)
          margin-bottom: 15px
      ul
        padding: 15px 0
        li
          position: relative
          padding-left: 10px
          &::before
            content: ''
            position: absolute
            left: 0
            top: 7.5px
            display: inline-block
            width: 3px
            height: 3px
            background-color: $gray_color
            border-radius: 100%
          &:not(:last-child)
            margin-bottom: 7.5px
      a[role="button"]
        display: flex
        align-items: center
        justify-content: center
        color: $white_color
        background-color: $ui_color
        border: 2px solid $ui_color
        border-radius: $radius
        margin-left: auto
        font-size: 16px
        font-weight: 500
        max-width: 100%
        width: 160px
        height: 35px
        &:active
          color: $ui_color
          background-color: transparent
        @media (hover: hover)
          &:hover
            color: $ui_color
            background-color: transparent
  &-body
    margin-top: 3px
    padding-right: 8px
  &-messages
    display: flex
    flex-direction: column
    padding: 0 12px 0 20px
    height: 356px
    overflow-y: auto
    &::-webkit-scrollbar
      width: 2px
      border-radius: 20px
      background-color: rgba($ui_color, 0.1)

    &::-webkit-scrollbar-thumb
      border-radius: 20px
      background-color: rgba($ui_color, 0.3)
  &-message
    border-radius: $radius
    margin: 0 0 20px 0
    padding: 18px 24px
    max-width: 83.664%
    opacity: 1
    &:first-child
      margin-top: 20px
    &.hidden
      opacity: 0
    &.message-user
      background-color: $ui_color
      color: $white_color
      border-top-right-radius: 0
      align-self: end
    &.message-assistant
      background-color: $white_color
      color: $gray_color
      border-bottom-left-radius: 0
      align-self: start
      a
        color: $ui_color
        border-bottom: 1px solid transparent
        display: inline-block
        line-height: 1
        &:active
          border-color: $ui_color
        @media (hover: hover)
          &:hover
            border-color: $ui_color
      // Если в ответе будут кнопки - тут менять стили
      .button__wrapper
        width: 100%
        margin: 10px 0
        button
          display: block
          margin-left: auto
          background-color: $ui_color
          color: $white_color
          max-width: 100%
          padding: 7px 50px
          border-radius: $radius
          &:active
            box-shadow: 0px 3px 13px 0px rgba(2, 66, 76, 0.5)
          @media (hover: hover)
            &:hover
              box-shadow: 0px 3px 13px 0px rgba(2, 66, 76, 0.5)

      ul
        padding: 15px 0
      li
        position: relative
        padding-left: 10px
        &::before
          content: ''
          position: absolute
          left: 0
          top: 7.5px
          display: inline-block
          width: 3px
          height: 3px
          background-color: $gray_color
          border-radius: 100%
        &:not(:last-child)
          margin-bottom: 7.5px
    &-content
      margin: 0
      padding: 0
      font-weight: 400
      font-size: 16px
      line-height: 19px
  &-footer
    background-color: transparent
    padding: 19px
  &-send
    position: relative

    button
      background-color: transparent
      position: absolute
      right: 16px
      top: 50%
      transform: translateY(-50%)
      &:active
        transform: translateY(-50%) scale(1.2)
      @media (hover: hover)
        &:hover
          transform: translateY(-50%) scale(1.2)
      &.locked
        pointer-events: none
        opacity: 0.5
      svg
        width: 24px
        height: 24px
        path
          fill: $ui_color
    input
      width: 100%
      font-size: 16px
      font-weight: 700
      line-height: 1
      text-align: right
      padding: 16px 48px 16px 16px
      border-radius: $radius
      border: 3px solid rgba(114, 200, 228, 0.7)
      box-shadow: 0px 3px 13px 0px rgba(2, 66, 76, 0.1)
      &:hover,
      &:focus
        box-shadow: 0px 3px 13px 0px rgba(2, 66, 76, 0.3)
        border: 3px solid rgba(114, 200, 228, 1)


@media (max-width: 1400px)
  .dialog__chat-page
    &-widget
      &-image
        max-width: 150px
      &-title
        font-size: 18px
        margin-bottom: 10px
      &-descr
        font-size: 14px
        p
          &:not(:last-child)
            margin-bottom: 10px
        ul
          padding: 10px 0
          li
            &::before
              top: 5px
        a[role="button"]
          font-size: 14px

@media (max-width: 1200px)
  .dialog__chat-page
    height: 100%
    &-widget
      &-image
        max-width: 130px
      &-title
        font-size: 16px
        margin-bottom: 10px
      &-descr
        font-size: 13px
        ul
          li
            &::before
              top: 4px
        a[role="button"]
          font-size: 13px
    &-messages
      height: 376px


@media (max-width: 767px)
  .dialog__chat-page
    border-radius: $radius
    &-header:not(.dialog__chat-page-header-widget)
      display: none
    &-body:not(.dialog__chat-page-body-widget)
      margin-top: 15px
      padding-right: 10px
    &-widget
      &-image
        max-width: 160px
    &-messages
      padding: 0 6px 0 16px
    &-message
      max-width: 92.11%
      padding: 19px
      &:first-child
        margin-top: 0
    &-footer
      padding: 12px
    &-send
      button
        right: 12px
      input
        font-size: 15px
        padding: 12px 44px 12px 16px
        text-align: left

@media (max-width: 576px)
  .dialog__chat-page
    &-widget
      &-image
        float: none
        display: block
        padding: 0
        margin: 0 auto
        max-width: 100%
        max-height: 320px
        height: 100%



@media (max-height: 750px)
  .dialog__chat-page
    border-radius: 4vh
    .backdrop
      border-radius: 4vh
    &-header
      height: 6.666vh
      padding: 0.8vh 1.333vh
    &-close
      width: 5.2vh
      height: 5.2vh
      svg
        width: 1.6vh
        height: 1.6vh
    &-contents-wrapper:not([data-id="#chat"])
      padding-bottom: 2.666vh
      padding-right: 1.066vh
      border-radius: 4vh
    &-widget
      .dialog__chat-page-body
        padding: 0 1.6vh 0 2.666vh
        height: calc(100% - 6.666vh)
        &::-webkit-scrollbar
          width: 0.266vh
          border-radius: 2.666vh
        &::-webkit-scrollbar-thumb
          border-radius: 2.666vh
      &-image
        max-width: 25.333vh
        float: left
        padding-right: 1.333vh
      &-title
        font-size: 2.666vh
        margin-bottom: 2.666vh
      &-descr
        font-size: 2.133vh
        p
          &:not(:last-child)
            margin-bottom: 2vh
        ul
          padding: 2vh 0
          li
            padding-left: 1.333vh
            &::before
              top: 1vh
              width: 0.4vh
              height: 0.4vh
            &:not(:last-child)
              margin-bottom: 1vh
        a[role="button"]
          border: 0.266vh solid $ui_color
          border-radius: 4vh
          font-size: 2.133vh
          width: 21.333vh
          height: 4.666vh
          // &:active
          //   color: $ui_color
          //   background-color: transparent
          // @media (hover: hover)
          //   &:hover
          //     color: $ui_color
          //     background-color: transparent
    &-body
      margin-top: 0.4vh
      padding-right: 1.066vh
    &-messages
      padding: 0 1.6vh 0 2.666vh
      height: 47.466vh
      &::-webkit-scrollbar
        width: 0.266vh
        border-radius: 2.666vh
      &::-webkit-scrollbar-thumb
        border-radius: 2.666vh
    &-message
      border-radius: 4vh
      margin: 0 0 2.666vh 0
      padding: 2.4vh 3.2vh
      max-width: 83.664%
      &:first-child
        margin-top: 2.666vh
      &.message-user
        border-top-right-radius: 0
      &.message-assistant
        border-bottom-left-radius: 0
        a
          border-bottom: 0.133vh solid transparent
        .button__wrapper
          margin: 1.333vh 0
          button
            padding: 0.933vh 6.666vh
            border-radius: 4vh
        ul
          padding: 2vh 0
        li
          position: relative
          padding-left: 1.333vh
          &::before
            content: ''
            position: absolute
            left: 0
            top: 1vh
            display: inline-block
            width: 0.4vh
            height: 0.4vh
            background-color: $gray_color
            border-radius: 100%
          &:not(:last-child)
            margin-bottom: 1vh
      &-content
        font-size: 1.733vh
        line-height: 2.533vh
    &-footer
      padding: 2.533vh
    &-send
      button
        right: 2.133vh
        top: 50%
        transform: translateY(-50%)
        svg
          width: 3.2vh
          height: 3.2vh
      input
        font-size: 2.133vh
        padding: 2.133vh 48px 2.133vh 2.133vh
        border-radius: 4vh
        border: 0.4vh solid rgba(114, 200, 228, 1)
        box-shadow: 0px 0.4vh 1.733vh 0px rgba(2, 66, 76, 0.1)

@media (max-width: 992px) and (max-height: 750px)
  .dialog__chat-page
    &-widget
      &-image
        max-width: 17.333vh
      &-title
        font-size: 2.133vh
        margin-bottom: 1.333vh
      &-descr
        font-size: 1.733vh
        ul
          li
            &::before
              top: 0.533vh
        a[role="button"]
          font-size: 1.733vh
          width: 17vh
          height: 3.5vh

@media (max-width: 767px) and (max-height: 750px)
  .dialog__chat-page
    margin: 0 1.33vh
    height: 78.34vh
    &-widget
      padding-bottom: 1vh
      &-image
        max-width: 21.333vh
    &-body
      margin-top: 2vh
      padding-right: 1.333vh
    &-messages
      padding: 0 0.8vh 0 2.133vh
      height: 64.6vh
    &-message
      max-width: 92.11%
      padding: 2.533vh
      &:first-child
        margin-top: 0
      &-content
        font-size: 2.16vh
        line-height: 3.166vh
    &-footer
      padding: 1.6vh
    &-send
      button
        right: 1.6vh
      input
        font-size: 2vh
        padding: 1.6vh 5.866vh 1.6vh 2.133vh


@media (max-width: 576px) and (max-height: 750px)
  .dialog__chat-page
    &-widget
      &-image
        float: none
        display: block
        padding: 0
        margin: 0 auto
        max-width: 100%
        max-height: 320px
        height: 100%