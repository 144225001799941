$ui_color: #00a3bb
$default_color: #000
$gray_color: rgba(101, 101, 101, 1)
$white_color: #ffffff

$header_height: 90px
$header_tablet_height: 70px
$header_mobile_height: 50px

$burger_wrapper_size: 24px
$burger_height: 16px
$burger_line_height: 2px

$tabs_height: 52px

$trans-dur: .2s
$trans-tim-func: linear

$radius: 30px
$ff: 'Nunito'