#helpModal
  top: $header_height
  .modal-dialog
    transform: translate(0, 0)
  .modal-dialog,
  .modal-content
    width: 100%
    height: calc(100% - $header_height)
    max-width: 100%
    margin: 0
    padding: 0
    border-radius: 0
    background-color: transparent
  .modal-header
    padding: 0
  .btn-close
    padding: 0
    margin: 40px 40px 0 auto
    width: 30px
    height: 30px
    svg
      width: 100%
      height: 100%
#donatModal
  .donat-modals 
    display: flex
    justify-content: center
@media (max-width: 1200px)
  #helpModal
    top: $header_tablet_height
    .modal-dialog,
    .modal-content
      height: calc(100% - $header_tablet_height)
    .btn-close
      margin: 25px 25px 0 auto
      width: 24px
      height: 24px

@media (max-width: 767px)
  #helpModal
    top: $header_mobile_height
    .modal-dialog,
    .modal-content
      height: calc(100% - $header_mobile_height)
    .btn-close
      margin: 20px 20px 0 auto
      width: 22px
      height: 22px

@media (max-width: 576px)
  #helpModal
    .modal-dialog
      background-color: rgba(36, 91, 99, 0.3)
    .btn-close
      margin: 10px 10px 0 0

@media (max-height: 750px)
  #helpModal
    top: 12vh
    .modal-dialog,
    .modal-content
      height: calc(100% - 12vh)
    .btn-close
      margin: 5.333vh 5.333vh 0 auto
      width: 4vh
      height: 4vh

@media (max-width: 1200px) and (max-height: 750px)
  #helpModal
    top: 9.333vh
    .modal-dialog,
    .modal-content
      height: calc(100% - 9.333vh)
    .btn-close
      margin: 3.333vh 3.333vh 0 auto
      width: 3.2vh
      height: 3.2vh

@media (max-width: 767px) and (max-height: 750px)
  #helpModal
    top: 6.666vh
    .modal-dialog,
    .modal-content
      height: calc(100% - 6.666vh)
    .btn-close
      margin: 2.666vh 2.666vh 0 auto
      width: 2.933vh
      height: 2.933vh

@media (max-width: 576px) and (max-height: 750px)
  #helpModal
    .modal-dialog
      background-color: transparent
    .btn-close
      margin: 1.333vh 1.333vh 0 0

@media (max-width: 400px) and (max-height: 600px)
  #helpModal
    .modal-dialog
      background-color: rgba(36, 91, 99, 0.3)