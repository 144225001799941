.dialog__interface-button-audio
  margin-right: 10px
  transition: $trans-dur $trans-tim-func
  position: relative
  &[data-visible="false"]
    display: none
    pointer-events: none
  &-icon
    padding: 19px
    background-color: rgba($white_color, 0.5)
    border-radius: 100%
    cursor: pointer
    &:active
      background-color: transparent
      .dialog__interface-button-audio-icon-wrapper
        box-shadow: 0px 0px 30px 0px rgba(34, 60, 80, 0.2)
    @media (hover: hover)
      &:hover
        background-color: transparent
        .dialog__interface-button-audio-icon-wrapper
          box-shadow: 0px 0px 30px 0px rgba(34, 60, 80, 0.2)
    &-wrapper
      padding: 26px
      width: 134px
      height: 134px
      background-color: $ui_color
      border: 15px solid $white_color
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
    svg
      display: block
      position: absolute
      width: 100%
      height: 100%
      max-width: 30px
      max-height: 30px

.dialog__interface-button
  margin-right: 10px
  transition: $trans-dur $trans-tim-func
  position: relative
  &.locked
    opacity: 0.5
    pointer-events: none
  &[data-visible="false"]
    display: none
    pointer-events: none
  &-icon
    padding: 19px
    background-color: rgba($white_color, 0.5)
    border-radius: 100%
    cursor: pointer
    &:active
      background-color: transparent
      .dialog__interface-button-icon-wrapper
        box-shadow: 0px 0px 30px 0px rgba(34, 60, 80, 0.2)
    @media (hover: hover)
      &:hover
        background-color: transparent
        .dialog__interface-button-icon-wrapper
          box-shadow: 0px 0px 30px 0px rgba(34, 60, 80, 0.2)
    &-wrapper
      padding: 26px
      width: 134px
      height: 134px
      background-color: $ui_color
      border: 15px solid $white_color
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      &.show-timer
        background-color:#00BA97
        #timer
          z-index: 1
          opacity: 1
      &.show-icon
        svg
          z-index: 1
          opacity: 1
    #timer
      z-index: -1
      opacity: 0
      display: block
      position: absolute
      font-size: 20px
      color: $white_color
      font-weight: 700
      line-height: 1
      white-space: nowrap
      display: flex
      align-items: center
      justify-content: center
    svg
      z-index: -1
      opacity: 0
      display: block
      position: absolute
      width: 100%
      height: 100%
      max-width: 52px
      max-height: 52px


@media (max-width: 1200px)
  .dialog__interface-button-audio,
  .dialog__interface-button
    margin-right: 0

@media (max-width: 767px)
  .dialog__interface-button-audio,
  .dialog__interface-button
    order: 3


@media (max-height: 750px)
  .dialog__interface-button-audio
    margin-right: 1.329vh
    &-icon
      padding: 2.533vh
      &-wrapper
        padding: 3.466vh
        width: 17.866vh
        height: 17.866vh
        border: 2vh solid $white_color
        svg
          max-width: 4vh
          max-height: 4vh

  .dialog__interface-button
    margin-right: 1.329vh
    &-icon
      padding: 2.533vh
      &-wrapper
        padding: 3.466vh
        width: 17.866vh
        height: 17.866vh
        border: 2vh solid $white_color
        span
          font-size: 2.666vh
        svg
          max-width: 6.933vh
          max-height: 6.933vh


@media (max-width: 767px) and (max-height: 750px)
  .dialog__interface-button-audio,
  .dialog__interface-button
    margin: 0