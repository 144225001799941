// Тут общие стили для блоков
.dialog__chat
  overflow: hidden
  border-radius: $radius
  position: relative
  width: 100%
  height: calc(100% - 20px)
  opacity: 1
  max-width: 548px
  max-height: 100%
  margin-left: 18px
  margin-bottom: auto
  transition: all $trans-dur $trans-tim-func, opacity $trans-dur $trans-tim-func $trans-dur
  &.invisible
    transition: all $trans-dur $trans-tim-func, max-width $trans-dur $trans-tim-func $trans-dur, max-height $trans-dur $trans-tim-func $trans-dur, margin-left $trans-dur $trans-tim-func $trans-dur
    opacity: 0
    max-width: 0
    max-height: 0
    margin-left: 0


@media (max-width: 1200px)
  .dialog__chat
    height: 100%

@media (max-height: 750px)
  .dialog__chat
    border-radius: 4vh
    height: calc(100% - 2.666vh)
    width: 73.066vh
    margin-left: 2.4vh

@media (max-width: 767px)
  .dialog__chat
    display: none


@import "./_chat"