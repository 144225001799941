/* stylelint-disable */

@mixin bg-cover
  background-position: center
  background-size: cover
  background-repeat: no-repeat

@mixin flex
  display: flex

@mixin flex-v-center
  display: flex
  align-items: center

@mixin flex-h-center
  display: flex
  justify-content: center

@mixin flex-all-center
  display: flex
  align-items: center
  justify-content: center

@mixin flex-all-sb
  display: flex
  align-items: center
  justify-content: space-between

$browser-context: 16 // Default

@function rem($pixels, $context: $browser-context)
  @return #{$pixels/$context}rem

@mixin image-set($pathToImage)
  background-image: url("#{$pathToImage}.jpg")
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set("#{$pathToImage}.avif"type("image/avif"),"#{$pathToImage}.webp"type("image/webp"),"#{$pathToImage}.jpg"type("image/jpg"))

@mixin footerToBottom
  display: grid
  grid-template-columns: 100%
  grid-template-rows: auto 1fr auto
  min-height: 100vh

@mixin mr($value)
    &:not(:last-child)
        margin-right: $value

@mixin ml($value)
    &:not(:last-child)
        margin-left: $value

@mixin mb($value)
    &:not(:last-child)
        margin-bottom: $value

@mixin mt($value)
    &:not(:last-child)
        margin-top: $value

@mixin pseudo()
    content: ""
    display: block
