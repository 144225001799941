.modal-backdrop
  --bs-backdrop-bg: rgba(36, 91, 99, 1)
  --bs-backdrop-opacity: 0.3
  &.help-open
    margin-top: $header_height
.modal-dialog-scrollable
  height: calc(100% - var(--bs-modal-margin)* 2 - 38px)
.modals
  .modal
    &-dialog,
    &-content
      border: 0
      border-radius: $radius
      max-width: 620px
    &-content
      padding-right: 6px
    &-header
      padding: 24px 20px 12px 30px
      border: 0
    &-title
      max-width: 95%
      font-size: 24px
      font-weight: 700
      line-height: 1
      color: $default_color
    .btn-close
      opacity: 1
      outline: 0
      background: transparent
      padding: 0
      margin: 0
      margin-left: auto
      width: 17px
      height: 17px
      display: flex
      align-items: center
      justify-content: center
      svg
        width: 17px
        height: 17px
      &:focus
        outline: 0
        opacity: 1
        box-shadow: none
      &:hover
        opacity: 0.75
    &-body
      position: static
      padding: 0 22px 22.5px 30px

      &::-webkit-scrollbar
        width: 2px
        border-radius: 20px
        background-color: rgba($ui_color, 0.1)

      &::-webkit-scrollbar-thumb
        border-radius: 20px
        background-color: rgba($ui_color, 0.3)
      &__shadow-inset
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 52.5px
        background: $white_color
        background: linear-gradient(180deg, rgba($white_color,0) 0%, rgba($white_color,1) 60%)
      a
        font-size: 16px
        font-weight: 400
        color: $ui_color
        border-bottom: 1px solid transparent
        transition: $trans-dur $trans-tim-func
        &:hover
          border-color: $ui_color

      p
        font-size: 16px
        line-height: 27px
        font-weight: 400
        color: $gray_color
        margin-bottom: 16px
        &.subtitle
          font-weight: 700
      ul
        margin-left: 27.5px
        margin-bottom: 16px
        font-size: 16px
        line-height: 27px
        font-weight: 400
        color: $gray_color
        li
          list-style: disc
      img
        margin-bottom: 16px

@media (max-width: 1200px)
  .modal-backdrop
    &.help-open
      margin-top: $header_tablet_height

@media (max-width: 767px)
  .modal-backdrop
    &.help-open
      margin-top: $header_mobile_height
  .modals
    .modal
      &-dialog,
      &-content
        max-width: 400px

@media (max-width: 576px)
  .modal-dialog-scrollable
    height: calc(100% - var(--bs-modal-margin) * 3 - 50px)
  .modals
    .modal
      &.show
        .modal-dialog
          transform: translate(0, 50px)
      &-content,
      &-dialog
        max-width: none
      &-header
        padding: 25px 20px 10px 20px
      .btn-close
        position: absolute
        right: 20px
        top: 20px
      &-body
        padding: 0 18px 15px 18px
        &__shadow-inset
          height: 30px
        a
          font-size: 13px
        p
          font-size: 13px
          line-height: 22px
        ul
          margin-left: 23px
          font-size: 13px
          line-height: 22px

@media (min-width: 576px)
  .modal
    --bs-modal-margin: 72px

@media (max-height: 750px)
  .modal-backdrop
    &.help-open
      margin-top: 12vh
  .modal
    --bs-modal-margin: 9.6vh
  .modal-dialog-scrollable
    height: calc(100vh - var(--bs-modal-margin) * 2 - 5.066vh)
  .modals
    .modal
      &-dialog,
      &-content
        border-radius: 4vh
        max-width: 82.666vh
      &-content
        padding-right: 0.8vh
      &-header
        padding: 3.2vh 2.666vh 1.6vh 4.285vh
      &-title
        font-size: 3.2vh
      .btn-close
        width: 2.266vh
        height: 2.266vh
        svg
          width: 2.266vh
          height: 2.266vh
      &-body
        padding: 0 2.933vh 3vh 4vh
        &::-webkit-scrollbar
          width: 0.266vh
          border-radius: 2.666vh

        &::-webkit-scrollbar-thumb
          border-radius: 2.666vh
        &__shadow-inset
          height: 7vh
        a
          font-size: 2.133vh
          border-bottom: 0.133vh solid transparent
        p
          font-size: 2.133vh
          line-height: 3.6vh
          margin-bottom: 2.133vh
        ul
          margin-left: 3.666vh
          margin-bottom: 2.133vh
          font-size: 2.133vh
          line-height: 3.6vh
        img
          margin-bottom: 2.133vh


@media (max-width: 1200px) and (max-height: 750px)
  .modal-backdrop
    &.help-open
      margin-top: 9.333vh

@media (max-width: 767px) and (max-height: 750px)
  .modal-backdrop
    --bs-backdrop-opacity: 0.3
    &.help-open
      margin-top: 6.666vh
  .modals
    .modal
      &-dialog,
      &-content
        max-width: 53.333vh

@media (max-width: 576px) and (max-height: 750px)
  .modal
    --bs-modal-margin: 3vh
  .modal-dialog-scrollable
    height: calc(100% - var(--bs-modal-margin) * 3 - 6.666vh)
  .modals
    .modal
      &.show
        .modal-dialog
          transform: translate(0, 6.666vh)
      &-content
        max-width: 93.75%
        margin: 0 auto
      &-dialog
        max-width: 50vh
        margin: var(--bs-modal-margin) auto
      &-header
        padding: 3.333vh 2.666vh 1.333vh 2.666vh
      .btn-close
        right: 2.666vh
        top: 2.666vh
      &-body
        padding: 0 2.4vh 2vh 2.4vh
        &__shadow-inset
          height: 4vh
        a
          font-size: 1.733vh
        p
          font-size: 1.733vh
          line-height: 2.933vh
        ul
          margin-left: 3.066vh
          font-size: 1.733vh
          line-height: 2.933vh

@media (max-width: 400px) and (max-height: 600px)
  .modal-dialog-scrollable
    height: calc(100% - var(--bs-modal-margin) * 3 - 3.748vh)
  .modals
    .modal
      &-content
        max-width: 50vh
        margin: 0 auto


@import "./_modal_contacts"
@import "./_modal_help"