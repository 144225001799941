.help__tooltip
  &.opened
    opacity: 1
  position: absolute
  width: 100vw
  max-width: 320px
  background-color: rgba($white_color, 0.8)
  border-radius: $radius
  padding: 22px
  opacity: 0
  z-index: -1
  backdrop-filter: blur(3px)
  &-content
    display: flex
    align-items: flex-start
    justify-content: space-between
  &-num
    background-color: rgba(249, 207, 0, 1)
    width: 34px
    height: 34px
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    font-weight: 700
    line-height: 1
    border-radius: 100%
    margin-right: auto
    padding-left: 5px
    span
      display: inline-block
      font-size: inherit
      font-weight: inherit
      line-height: 0
  &-text
    font-size: 16px
    font-weight: 700
    line-height: 22px
    max-width: 215px

  &-first
    bottom: -40px
    left: 50%
    transform: translateX(-50%)
    @media (max-height: 800px)
      bottom: -2vh
  &-second
    right: -65%
    top: 65px
  &-third
    left: -29.5%
    bottom: -17.5px


@media (max-width: 1200px)
  .help__tooltip
    max-width: 280px
    padding: 18px
    &-num
      width: 30px
      height: 30px
      font-size: 14px
      padding-left: 2.5px
    &-text
      font-size: 14px
      line-height: 20px
      max-width: 190px

@media (max-width: 992px)
  .help__tooltip
    &-first
      bottom: -15px
    &-third
      left: -5%


@media (max-width: 767px)
  .help__tooltip
    padding: 22px
    &-num
      width: 34px
      height: 34px
      font-size: 16px
      line-height: 1
      padding-left: 5px
    &-text
      font-size: 16px
      line-height: 22px
    &-first
      bottom: -40px
    &-second
      right: -20%
      top: -10px
    &-third
      left: -20%

@media (max-width: 576px)
  .help__tooltip
    padding: 20px
    max-width: 240px
    &-num
      width: 24px
      height: 24px
      font-size: 13px
      line-height: 1
      padding-left: 2px
    &-text
      font-size: 13px
      line-height: 18px
      max-width: 160px
    &-first
      bottom: -30px
    &-second
      right: -5px
      top: -15px
    &-third
      left: -5px


@media (max-height: 750px)
  .help__tooltip
    max-width: 42.666vh
    border-radius: 4vh
    padding: 2.933vh
    backdrop-filter: blur(0.4vh)
    &-num
      width: 4.533vh
      height: 4.533vh
      font-size: 2.133vh
      padding-left: 0.66vh
    &-text
      font-size: 2.133vh
      line-height: 2.933vh
      max-width: 28.666vh

    &-second
      right: -65%
      top: 8.666vh
    &-third
      left: -29.5%
      bottom: -2.333vh

@media (max-width: 1200px) and (max-height: 750px)
  .help__tooltip
    max-width: 37.333vh
    padding: 2.4vh
    &-num
      width: 4vh
      height: 4vh
      font-size: 1.866vh
      padding-left: 0.333vh
    &-text
      font-size: 1.866vh
      line-height: 2.666vh
      max-width: 25.333vh

@media (max-width: 992px) and (max-height: 750px)
  .help__tooltip
    &-first
      bottom: -2vh
    &-third
      left: -5%

@media (max-width: 767px) and (max-height: 750px)
  .help__tooltip
    padding: 2.933vh
    &-num
      width: 4.533vh
      height: 4.533vh
      font-size: 2.133vh
      padding-left: 0.666vh
    &-text
      font-size: 2.133vh
      line-height: 2.933vh
    &-first
      bottom: -5.333vh
    &-second
      right: -20%
      top: -1.333vh
    &-third
      left: -20%

@media (max-width: 576px) and (max-height: 750px)
  .help__tooltip
    padding: 2.666vh
    max-width: 32vh
    &-num
      width: 3.2vh
      height: 3.2vh
      font-size: 1.733vh
      padding-left: 0.266vh
    &-text
      font-size: 1.733vh
      line-height: 2.4vh
      max-width: 21.333vh
    &-first
      bottom: -4vh
    &-second
      right: 0.666vh
      top: -2vh
    &-third
      left: 0.666vh