// Тут общие стили для блоков
.dialog__interface
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  width: 100%
  max-width: 364px
  .tab-content
    width: 100%
    height: 340px
    margin-bottom: 15px
    position: relative
    .tab-pane
      height: 100%
    .dialog__btn-open-chat
      display: flex
      align-items: center
      justify-content: center
      background-color: $ui_color
      width: 44px
      height: 44px
      border-radius: 100%
      position: absolute
      top: 10px
      right: 10px
      z-index: 2
      svg
        width: 54.54%
      &[data-visible='false']
        opacity: 0
        pointer-events: none
      &:active
        background-color: rgba($ui_color, 0.1)
        svg
          path
            fill: $ui_color
      @media (hover: hover)
        &:hover
          background-color: rgba($ui_color, 0.1)
          svg
            path
              fill: $ui_color


@media (max-width: 1200px)
  .dialog__interface
    max-width: 320px
    margin-bottom: auto
    .tab-content
      height: 298.9px

@media (max-width: 767px)
  .dialog__interface
    max-width: 400px
    flex-direction: column
    flex-wrap: nowrap
    margin-bottom: 0
    height: 100%
    .tab-content
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      margin: 20px 0
      order: 2
      .dialog__btn-open-chat
        display: none
      .tab-pane
        height: 100%

@media (max-width: 576px)
  .dialog__interface
    max-width: 93.75%

@media (max-height: 750px)
  .dialog__interface
    max-width: 48.533vh
    .tab-content
      height: 45.333vh
      margin-bottom: 2vh
      .dialog__btn-open-chat
        width: 5.866vh
        height: 5.866vh
        top: 1.333vh
        right: 1.333vh

@media (max-width: 767px) and (max-height: 750px)
  .dialog__interface
    max-width: 50vh
    padding-top: 10vh
    justify-content: flex-start
    .tab-content
      height: 100%
      margin: 3.333vh 0
      order: 2



@import './_interface_indicator'
@import './_interface_video'
@import './_interface_type_switch'
@import './_interface_button'

