/* stylelint-disable */

@mixin burger
  $burger-width: 20px
  $burger-height: 16px
  $burger-line-height: 12.5%

  $burger-vh-width: 2.666vh
  $burger-vh-height: 2.133vh
  $burger-vh-line-height: 0.266vh

  position: relative
  border: none
  padding: 0
  width: $burger-width
  height: $burger-height
  color: $ui_color
  background-color: transparent
  cursor: pointer
  // @media (max-width: 767px) and (max-height: 750px)
  //   width: $burger-vh-width
  //   height: $burger-vh-height
  &::before,
  &::after
    content: ""
    position: absolute
    left: 0
    width: 100%
    height: $burger-line-height
    // @media (max-width: 767px) and (max-height: 750px)
    //   height: $burger-vh-line-height
    background-color: currentColor
    transition: transform 0.15s ease-in-out, top 0.15s ease-in-out
  &::before
    top: 0
  &::after
    top: calc(100% - $burger-line-height)
    // @media (max-width: 767px) and (max-height: 750px)
    //   top: calc(100% - $burger-vh-line-height)
  &-line
    position: absolute
    left: 0
    top: 50%
    width: 100%
    height: $burger-line-height
    background-color: currentColor
    transform: translateY(-50%)
    transition: transform 0.15s ease-in-out
    // @media (max-width: 767px) and (max-height: 750px)
    //   height: $burger-vh-line-height
  &[aria-expanded="true"]
    &::before
      top: 50%
      transform: rotate(45deg)
      transition: transform 0.15s ease-in-out, top 0.15s ease-in-out
    &::after
      top: 50%
      transform: rotate(-45deg)
      transition: transform 0.15s ease-in-out, top 0.15s ease-in-out
    .header__burger-line
      transform: scale(0)
      transition: transform 0.15s ease-in-out