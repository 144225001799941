.dialog__interface-type-switch
  margin-left: 10px
  position: relative
  .nav
    height: $tabs_height
    display: inline-flex
    border-radius: $radius
    border: 4px solid $white_color
    background-color: $white_color
    &-item
      &:nth-child(2),
      &:first-child
        .nav-link
          padding-left: 3px
          border-top-left-radius: $radius
          border-bottom-left-radius: $radius
      &:last-child
        .nav-link
          padding-right: 3px
          border-top-right-radius: $radius
          border-bottom-right-radius: $radius
      .nav-link#chat-tab
        display: none
    &-link
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      border-radius: 0
      border: 0
      width: 60px
      height: 100%
      background-color: rgba($ui_color, 0.1)
      &:active
        background-color: $ui_color
        svg
          path,rect
            fill: $white_color
      @media (hover: hover)
        &:hover
          background-color: $ui_color
          svg
            path,rect
              fill: $white_color
      &:focus,
      &:active,
      &:focus-visible
        outline: none
        box-shadow: none
      svg
        path,rect
          fill: $ui_color
      &.active
        background-color: $ui_color
        svg
          path,rect
            fill: $white_color

@media (max-width: 1200px)
  .dialog__interface-type-switch
    margin-left: 0


@media (max-width: 767px)
  .dialog__interface-type-switch
    order: 1
    .nav
      &-item
        &:nth-child(2)
          .nav-link
            padding: 0
            border-radius: 0
        .nav-link#chat-tab
          display: flex

@media (max-height: 750px)
  .dialog__interface-type-switch
    margin-left: 1.329vh
    .nav
      height: 6.933vh
      border-radius: 4vh
      border: 0.533vh solid $white_color
      &-item
        &:nth-child(2),
        &:first-child
          .nav-link
            padding-left: 0.4vh
            border-top-left-radius: 4vh
            border-bottom-left-radius: 4vh
        &:last-child
          .nav-link
            padding-right: 0.4vh
            border-top-right-radius: 4vh
            border-bottom-right-radius: 4vh
      &-link
        width: 8vh
        &#voice-tab
          svg
            width: 3.866vh
            height: 3.2vh
        &#video-tab
          svg
            width: 4.4vh
            height: 3.333vh
        &#chat-tab
          svg
            width: 3.2vh
            height: 2.4vh


@media (max-width: 767px) and (max-height: 750px)
  .dialog__interface-type-switch
    margin: 0
    order: 1
    .nav
      &-item
        &:nth-child(2)
          .nav-link
            padding: 0
            border-radius: 0