.header
  width: 100%
  height: $header_height
  display: flex
  align-items: center
  justify-content: center
  background-color: $white_color
  position: absolute
  left: 0
  top: 0
  padding: 0
  &.menu-open
    .header__logo,
    .header__links
      opacity: 0
  .row
    align-items: center
    justify-content: center
  &__row
    display: flex
    align-items: center
    justify-content: space-between
  &__logo
    display: flex
    align-items: center
    justify-content: center
    &-image
      width: 100%
      max-width: 58px
    &-text
      font-size: 27px
      font-weight: 500
      margin-left: 20px
      color: $default_color
  &__links
    display: flex
    align-items: center
    justify-content: center
    &-item
      display: flex
      align-items: center
      justify-content: center
      border-radius: 100%
      padding: 5px
      background-color: $white_color
      &:not(:last-child)
        margin-right: 15px
      &:hover,
      &.active
        background-color: $ui_color
        .header__links-icon
          path
            fill: $white_color
      &.active
        pointer-events: none
    &-icon
      width: 42px
      height: 42px

  &__burger
    // @include burger
    $lineTranslateY: calc((($burger_height - (3 * $burger_line_height)) / 2) + $burger_line_height)
    display: none
    z-index: 1065
    color: $ui_color
    cursor: pointer
    padding: 0
    svg
      display: inline-block
      fill: currentColor
      vertical-align: middle
      width: $burger_wrapper_size
      height: $burger_wrapper_size
      .g
        .line
          opacity: 1
          transform: rotate(0) translateY(0) translateX(0)
          transform-origin: center
        .line-1
          transform-origin: center
        .line-3
          transform-origin: center

    &[aria-expanded='true']
      .g
        .line-1
          transform: rotate(45deg) translateY($lineTranslateY) translateX(0)
        .line-2
          opacity: 0
          transform: rotate(-45deg) translateY(0) translateX(0)
        .line-3
          transform: rotate(-45deg) translateY(calc($lineTranslateY * -1)) translateX(0)


@media (max-width: 1200px)
  .header
    height: $header_tablet_height
    &__logo
      &-image
        max-width: 50px
      &-text
        font-size: 24px
        margin-left: 15px
    &__links
      &-item
        padding: 3.5px
        &:not(:last-child)
          margin-right: 12.5px
      &-icon
        width: 30px
        height: 30px

@media (max-width: 767px)
  .header
    height: $header_mobile_height
    z-index: 1070
    &__row
      padding: 0 20px
    &__logo
      margin-right: auto
      &-image
        display: none
      &-text
        font-size: 15px
        margin-left: 0
    &__links
      &-item
        &:not(:last-child)
          margin-right: 10px
      &-icon
        width: 28px
        height: 28px
    &__burger
      display: inline-block
      margin-left: 15px

@media (max-height: 750px)
  .header
    height: 12vh
    &__logo
      &-image
        max-width: 7.733vh
      &-text
        font-size: 3.6vh
        margin-left: 2.666vh
    &__links
      &-item
        padding: 0.933vh
        &:not(:last-child)
          margin-right: 2vh
      &-icon
        width: 5.066vh
        height: 5.066vh

@media (max-width: 1200px) and (max-height: 750px)
  .header
    height: 9.333vh
    &__logo
      &-image
        max-width: 6.666vh
      &-text
        font-size: 3.2vh
        margin-left: 2vh
    &__links
      &-item
        padding: 0.666vh
        &:not(:last-child)
          margin-right: 1.666vh
      &-icon
        width: 4vh
        height: 4vh

@media (max-width: 767px) and (max-height: 750px)
  .header
    height: 6.666vh
    &__row
      padding: 0 2.666vh
    &__logo
      margin-right: auto
      &-image
        display: none
      &-text
        font-size: 2.25vh
        margin-left: 0
    &__links
      &-item
        padding: 2px
        &:not(:last-child)
          margin-right: 1.333vh
      &-icon
        width: 3.733vh
        height: 3.733vh
    &__burger
      display: flex
      margin-left: 2vh
      svg
        width: 3.2vh
        height: 3.2vh
