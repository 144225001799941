*,*::before,*::after
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box
  font-family: $ff
  font-weight: 300
  padding: 0
  margin: 0
  border: 0
  transition: $trans-dur $trans-tim-func
  &:focus, &:active
    outline: none !important
nav,footer,header,aside
  display: block
html,body
  height: 100%
  width: 100%
  line-height: 1
  -ms-text-size-adjust: 100%
  -moz-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%
a
  text-decoration: none
  &:focus,&:active
    outline: none
  &::visited, &:hover
    text-decoration: none
button
  cursor: pointer
  &::-moz-focus-inner
    padding: 0
    border: 0
input,button, select, textarea
  font-family: inherit
  background-color: $white_color
  outline: none !important
  &:focus,&:active
    outline: none !important
input:-ms-clear
  display: none
ul,ol
  margin: 0
  padding: 0
  li
    list-style: none
img
  vertical-align: top
h1,h2,h3,h4,h5,h6
  font-size: inherit
  font-weight: 400

.page
  height: 100%
  -webkit-text-size-adjust: 100%

.page__body
  margin: 0
  min-width: 320px
  min-height: 100%
  position: relative
  background-color: $white_color
  &.menu-open-body--hidden
    overflow: hidden

.container
  @media (max-height: 750px)
    max-width: 176vh
  @media (max-width: 992px) and (max-height: 750px)
    max-width: 100vh

img
  height: auto
  max-width: 100%
  object-fit: cover

.site-container
  height: 100vh
  overflow: hidden
  position: relative
  display: flex
  flex-direction: column
  align-items: flex-end
  margin: 0 auto
  .main
    width: 100%
    height: 100vh
    &-home
      background-image: url('../img/bg.jpg')
      background-size: cover
      background-repeat: no-repeat
      background-position: center
    &-store
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
  &.site--hidden
    opacity: 0
  @media  ( max-height: 750px)
    height: auto
    overflow: auto

.is-hidden
  opacity: 0 !important
  visibility: hidden !important
  overflow: hidden !important

.input-reset
  -webkit-appearance: none
  appearance: none
  border: none
  border-radius: 0
  background-color: $white_color
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration
    display: none

.dis-scroll
  position: fixed
  left: 0
  top: 0
  overflow: hidden
  width: 100%
  height: 100vh
  overscroll-behavior: none

.page--ios .dis-scroll
  position: relative

.link-locked
  opacity: 0.5 !important
  cursor: auto !important
  pointer-events: none !important

.hidden
  display: none !important