.footer
  width: 100%
  padding: 20px 0 30px 0
  background-color: $white_color
  position: fixed
  left: 0
  bottom: 0
  z-index: 1060
  .row
    align-items: center
    justify-content: center
  &__row
    display: flex
    align-items: center
    justify-content: space-between
  &__copyright
    position: absolute
    bottom: 0
    left: 0
    padding: 0 1% 0.5% 0
    width: 100%
    text-align: right
    color: #ccc
    font-size: 12px
  &__menu
    display: flex
    align-items: center
    &-item
      &:not(:last-child)
        margin-right: 55px
    &-link
      display: inline-block
      font-size: 16px
      font-weight: 500
      border-bottom: 0.5px solid #5f5c67
      color: #5f5c67
      height: auto
      &:hover
        color: $ui_color
        border-color: transparent
      &.active
        color: $ui_color
        border-color: transparent
        pointer-events: none
  &__dev
    display: flex
    align-items: center
    justify-content: space-between
    &-image
      max-width: 42px
    &-text
      display: inline-block
      font-size: 13px
      font-weight: 400
      margin-left: 15px
      max-width: 130px
      color: $gray_color



// Начало - Правки для выпадающего меню на телефоне
.footer
  .collapse
    width: 100%
    display: block
    // position: relative
    .footer__menu-wrapper
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      min-height: 100%
      @media (max-width: 767px)
        flex-direction: column
        justify-content: flex-end
    @media (max-width: 767px)
      position: absolute
      left: 0
      top: 0
      z-index: 1
      width: 100%
      height: 100vh
      background: $white_color
      opacity: 1
      &:not(.show)
        display: none !important
  .collapsing
    position: absolute
    left: 0
    top: 0
    background: $white_color
    width: 100%
    transition: $trans-dur $trans-tim-func
    .footer__menu,
    .footer__dev
      transition: 0s
      opacity: 0
// Конец - Правки для выпадающего меню на телефоне


@media (max-width: 1200px)
  .footer
    padding: 15px 0 20px 0
    &__copyright
      font-size: 11px
    &__menu
      &-item
        &:not(:last-child)
          margin-right: 40px
      &-link
        font-size: 14px
    &__dev
      &-image
        max-width: 36px
      &-text
        font-size: 11px
        margin-left: 10px

@media (max-width: 767px)
  .footer
    width: 100%
    padding: 0
    background-color: transparent
    position: static
    &__copyright
      text-align: center
    &__collapse
      padding: 5px 16px 20px 16px
    &__menu
      flex-direction: column
      &-item
        margin-top: 55px
        &:not(:last-child)
          margin-right: 0
      &-link
        display: inline-block
        font-size: 18px
    &__dev
      margin-top: auto
      margin-right: auto
      &-image
        max-width: 42px
      &-text
        font-size: 13px
        font-weight: 400
        margin-left: 15px
        max-width: 130px


@media (max-height: 750px)
  .footer
    padding: 2.666vh 0 4vh 0
    &__copyright
      font-size: 1.6vh
    &__menu
      &-item
        &:not(:last-child)
          margin-right: 7.333vh
      &-link
        font-size: 2.133vh
        border-bottom: 0.066vh solid #5f5c67
    &__dev
      &-image
        max-width: 5.6vh
      &-text
        font-size: 1.733vh
        margin-left: 2vh
        max-width: 17.333vh


@media (max-width: 1200px) and (max-height: 750px)
  .footer
    padding: 2vh 0 2.666vh 0
    &__copyright
      font-size: 1.466vh
    &__menu
      &-item
        &:not(:last-child)
          margin-right: 5.333vh
      &-link
        font-size: 1.866vh
    &__dev
      &-image
        max-width: 4.8vh
      &-text
        font-size: 1.466vh
        margin-left: 1.333vh


@media (max-width: 767px) and (max-height: 750px)
  .footer
    padding: 0
    &__collapse
      padding: 0.833vh 2.666vh 2.333vh 2.666vh
    &__menu
      &-item
        margin-top: 9.166vh
        &:not(:last-child)
          margin-right: 0
      &-link
        font-size: 3vh
    &__dev
      margin-top: auto
      margin-right: auto
      &-image
        max-width: 7vh
      &-text
        font-size: 2.166vh
        margin-left: 2.333vh
        max-width: 21.5vh